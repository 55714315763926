import React from "react";
import { UserContext } from "./context/UserContext";

// Analytics providers
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import Intercom from "react-intercom";

const Analytics = () => {
  const userContext = React.useContext(UserContext);

  React.useEffect(() => {
    // google analytics
    ReactGA.initialize("UA-151567819-1");
    ReactGA.pageview(window.location.pathname + window.location.search);

    // facebook pixel
    ReactPixel.init("722929731560508");
    ReactPixel.pageView();
  }, []);

  const intercomObject = {
    authenticated: userContext.isAuthenticated,
    ...userContext.user,
  };

  intercomObject.id = intercomObject._id;

  if (intercomObject.authenticated) {
    intercomObject.registered = true;
  }

  return (
    <>
      <Intercom appID="muastr6j" {...intercomObject} />
    </>
  );
};

export default Analytics;
