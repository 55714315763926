import React from "react";
import { Col, Row, Visible } from "react-grid-system";
import { CreditCompanyLogo } from "../../../components";
import {
  Card,
  Separator,
  Typography,
  PaddingWrapper,
  Button,
} from "../../../ui";
import OfferStatusBadge from "./OfferStatusBadge";
import { AiOutlineInfoCircle } from "react-icons/ai";
import OfferAcceptDialog from "./OfferAcceptDialog";
import { UserContext } from "../../../context/UserContext";
import Cross from "../../../assets/img/cross.svg";
import "./offer.css";
// import { ReactComponent as infoSign } from "../../../assets/img/infoSign.png";
import { useState } from "react";

const Offer = ({
  creditCompany,
  handleAccept = () => null,
  style = {},
  hideLabel = false,
}) => {
  const userContext = React.useContext(UserContext);
  //console.log(creditCompany)
  const InfoSign = require(`../../../assets/img/infoSign.png`);
  // let bvkkmnList = [];
  
  // for(let company of creditCompany) {
  //   console.log(company)
  //   // if(confirmation.creditConfirmation !== undefined) {
  //   //   //console.log(confirmation.creditConfirmation);
  //   //   bvkkmnList.push({creditCompany : confirmation.creditConfirmation.creditCompanyName, bvkkmn : confirmation.creditConfirmation.BVKKMN});
  //   // }
  // }
  
  // let byBvkkmn = bvkkmnList.slice(0);
  // byBvkkmn.sort(function (a, b) {
  //   return a.bvkkmn - b.bvkkmn;
  // });
  //console.log(creditCompany)
  const getOfferStatus = (creditCompany) => {
    const { creditConfirmation } = creditCompany;
    const { visible } = creditCompany;
    
    
    let status = "waiting_offer";

    if (!creditCompany.visible) {
      status = "declined";
    }

    if (visible && creditConfirmation) {
      if (creditConfirmation.type === "accept") {
        status = "accepted";
      }

      if (creditConfirmation.type === "decline") {
        status = "declined";
      }

      if (creditConfirmation.type === "decline") {
        status = "declined";
      }

      if (creditConfirmation.type === "lacking-info") {
        const requiredDocuments = creditConfirmation.requiredDocuments || [];
        const userRequiredDocuments = userContext.user.requiredDocuments || [];

        const uploaded = [];

        userRequiredDocuments.forEach((document) => {
          if (
            document &&
            document.files &&
            document.files.length > 0 &&
            requiredDocuments.includes(document.type)
          ) {
            uploaded.push(true);
          }
        });

        if (uploaded.length === requiredDocuments.length) {
          status = "waiting_offer";
        } else {
          status = "waiting_info";
        }
      }
    }
    return status;
  };
//   var cardStyle = {
//     display: 'inline-block',
//     width: '350px',
//     // left: '1038px',
//     transitionDuration: '0.3s',
    
//     height: '130px'
// }
  const { creditConfirmation } = creditCompany;
  const status = getOfferStatus(creditCompany);
  const [offerAcceptDialogOpen, setOfferAcceptDialogOpen] = React.useState(
    false
  );

  const [hide, setHide] = useState(false);

  const toggleHide = () => {
      setHide((oldState) => !oldState);
  };
  
  return (
    
    <Card className="padding px-3 px-sm-4 " style={style}>
      {/* <button className="button recommendation">Rekomenduojama</button> */}

      {/* <div className="recommendation">Rekomenduojama</div> */}
      {/* DESKTOP / TABLET */}

      <Visible  md lg xl xxl>
        <Row gutterWidth={0}>
          

          {!hideLabel && (
            <Col  className="my-auto">
              {creditCompany.hiddenLabelName}
              {/* <CreditCompanyLogo creditCompany={creditCompany} /> */}
            </Col>
          )}

          <Col className="text-right my-auto">
            <Typography.Header2 inline>
              {/* {!hideLabel ? creditCompany.name : creditCompany.hiddenLabelName} */}
            </Typography.Header2>{" "}
            <OfferStatusBadge status={status} />
          </Col>
        </Row>
      </Visible>

      {/* MOBILE */}
      <Visible xs sm>
        <Row gutterWidth={0} className="py-3">
          {!hideLabel && (
            <Col xs={12} sm={12} className="mb-3 mt-2">
              {creditCompany.hiddenLabelName}
              {/* <CreditCompanyLogo creditCompany={creditCompany} /> */}
            </Col>
          )}

          <Col className="my-auto">
            <Typography.Header3 reduceSizeMobile className="my-0" inline>
              {/* {!hideLabel ? creditCompany.name : creditCompany.hiddenLabelName} */}
            </Typography.Header3>
          </Col>

          <Col className="my-auto">
            <OfferStatusBadge status={status} />
          </Col>
        </Row>
      </Visible>

      {status === "declined" && creditConfirmation.reason && (
        <>
          <PaddingWrapper>
            <Separator />
          </PaddingWrapper>

          <Typography.Text size="small" weight={500} className="mt-0" greyDark>
            {creditConfirmation.reason}
          </Typography.Text>
        </>
      )}

      {status === "accepted" && (
        <>
          <PaddingWrapper>
            <Separator />
          </PaddingWrapper>

          {/* DESKTOP VERSION */}
          <Visible lg xl xxl>
            <Row gutterWidth={0}>
              <Col>
                <Typography.InlineText grey size="micro">
                  PASIŪLYMO SUMA
                </Typography.InlineText>
              </Col>

              <Col>
                <Typography.InlineText grey size="micro">
                  TERMINAS
                </Typography.InlineText>
              </Col>

              <Col>
                <Typography.InlineText grey size="micro">
                  ĮMOKA
                </Typography.InlineText>
              </Col>

              <Col sm={2} md={2} lg={2} xl={2.5} xxl={2}>
                <Typography.InlineText grey size="micro">
                  PALŪKANOS
                </Typography.InlineText>
              </Col>
            </Row>

            <Row gutterWidth={0}>
              <Col>
                <Typography.Header1 inline className="mr-2 m-0">
                  {creditConfirmation.creditOfferFrom} €
                </Typography.Header1>
                {creditConfirmation.creditOfferFrom !==
                  creditConfirmation.creditOfferTo && (
                  <Typography.Text greyDark inline className="my-0">
                    Iki: {creditConfirmation.creditOfferTo} €
                  </Typography.Text>
                )}
              </Col>

              <Col>
                <Typography.Header1 inline className="m-0">
                  {creditConfirmation.creditLength}
                </Typography.Header1>
                <Typography.Text inline className="ml-1">
                  mėn.
                </Typography.Text>
              </Col>

              <Col>
                <Typography.Header1 inline className="m-0">
                  {creditConfirmation.monthlyPayment} €
                </Typography.Header1>
                <Typography.Text inline className="ml-1">
                  / mėn.
                </Typography.Text>
              </Col>

              <Col sm={2} md={2} lg={2} xl={2.5} xxl={2}>
                <Typography.Header1 inline className="mr-2 m-0">
                  {creditConfirmation.interestRate}%
                </Typography.Header1>
                <Typography.Text greyDark inline className="my-0">
                  BVKKMN: {creditConfirmation.BVKKMN}%
                </Typography.Text>
              </Col>
            </Row>
          </Visible>

          {/* MOBILE / TABLET */}
          <Visible xs sm md>
            <Row>
              <Col xs={6} className="mt-3">
                <Typography.InlineText grey size="micro">
                  PASIŪLYMO SUMA
                </Typography.InlineText>
                <Typography.Header1 reduceSizeMobile className="m-0">
                  {creditConfirmation.creditOfferFrom} €
                </Typography.Header1>
              </Col>

              {creditConfirmation.creditOfferFrom !==
                creditConfirmation.creditOfferTo && (
                <Col xs={6} className="mt-3">
                  <Typography.InlineText grey size="micro">
                    MAKSIMALI SUMA
                  </Typography.InlineText>
                  <Typography.Header1 reduceSizeMobile className="m-0">
                    {creditConfirmation.creditOfferTo} €
                  </Typography.Header1>
                </Col>
              )}

              <Col xs={6} className="mt-3">
                <Typography.InlineText grey size="micro">
                  TERMINAS
                </Typography.InlineText>

                <div>
                  <Typography.Header1 reduceSizeMobile inline className="m-0">
                    {creditConfirmation.creditLength}
                  </Typography.Header1>
                  <Typography.Text inline className="ml-1 my-0">
                    mėn.
                  </Typography.Text>
                </div>
              </Col>

              <Col xs={6} className="mt-3">
                <Typography.InlineText grey size="micro">
                  ĮMOKA
                </Typography.InlineText>

                <div>
                  <Typography.Header1 reduceSizeMobile inline className="m-0">
                    {creditConfirmation.monthlyPayment} €
                  </Typography.Header1>
                  <Typography.Text inline className="ml-1 my-0">
                    / mėn.
                  </Typography.Text>
                </div>
              </Col>

              <Col xs={12} className="mt-3">
                <Typography.InlineText grey size="micro">
                  PALŪKANOS
                </Typography.InlineText>
                <br />
                <Typography.Header1 inline reduceSizeMobile className="m-0">
                  {creditConfirmation.interestRate}%
                </Typography.Header1>
                <Typography.Text
                  greyDark
                  size="small"
                  inline
                  className="ml-1 my-0"
                >
                  BVKKMN: {creditConfirmation.BVKKMN}%
                </Typography.Text>
              </Col>
            </Row>
          </Visible>
        <Row>
          <Col>
          {creditConfirmation.fee ||
          creditConfirmation.entranceFee ||
          creditConfirmation.share ? (
            <Typography.Text greyDark size="small" classname="mt-0">
              {creditConfirmation.fee ? (
                <>
                  Sutarties mokestis - {creditConfirmation.fee} € <br />
                </>
              ) : (
                ""
              )}

              {creditConfirmation.entranceFee ? (
                <>
                  Stojimo mokestis - {creditConfirmation.entranceFee} € <br />
                </>
              ) : (
                ""
              )}

              {creditConfirmation.share ? (
                <>
                  Pajinis įnašas - {creditConfirmation.share} € <br />
                </>
              ) : (
                ""
              )}
            </Typography.Text>
          ) : (
            ""
          )}

          
          </Col>
          {creditCompany.benefits !== undefined ?
          <Col className="">
          
          <p onClick={toggleHide} className="popUp">Papildomos naudos <img src={InfoSign} alt=""></img>
          {hide && (
            <Card className={`box stack-top`}>
              <p className="">Papildomos naudos</p> 
              <img src={Cross} alt=""></img>
              <ul>
                {creditCompany.benefits !== undefined ? creditCompany.benefits.map((benefit, index) => ( 
                  <li>  
                    {benefit}
                  </li>
                )):""}
              </ul>
            </Card>
          )}
          </p> 
          </Col>
          : ""}               
          </Row>
          {creditConfirmation.comment && (
            <>
              <PaddingWrapper>
                <Separator />
              </PaddingWrapper>
              <div className="mt-2 mb-3">
                <AiOutlineInfoCircle
                  fill="#FFC700"
                  size={24}
                  className="pr-1"
                />
                <Typography.InlineText
                  inline
                  style={{
                    verticalAlign: "5px",
                  }}
                >
                  {creditConfirmation.comment}
                </Typography.InlineText>
              </div>
            </>
          )}
        
        
          <div className="pb-2 mt-3">
            <Button fullWidth onClick={() => setOfferAcceptDialogOpen(true)}>
              Peržiūrėti pasiūlymą
            </Button>
          </div>

          <OfferAcceptDialog
            isOpen={offerAcceptDialogOpen}
            onClose={() => setOfferAcceptDialogOpen(false)}
            creditConfirmation={creditConfirmation}
            hideLabel={hideLabel}
            hiddenLabel={creditCompany.hiddenLabelName}
          />
        </>
      )}
    </Card>
  );
};

export default Offer;
